<template>
    <div class="container mt-5 pt-5">
    <b-row>
        <b-col class="col-12 col-md-6 mx-auto">
            <b-row>
                <b-col class="text-center col-12 col-md-6">
                    <h2 class="fw700 genBlueDark">Office Hours (EST)</h2>
                    <hr class="col-6">
                    <p class="fw-500 bold mb-0">Monday - Thursday</p>
                    <p class="fw-300 fs15">9am - 5pm</p>
                    <p class="fw-500 bold mb-0">Friday</p>
                    <p class="fw-300 fs15">9am - 3pm</p>
                    <p class="fw-500 bold mb-0">Saturday & Sunday</p>
                    <p class="fw-300 fs15 mb-0">Closed</p>
                </b-col>
                    
                <b-col class="text-center col-12 col-md-6">
                    <h2 class="fw700 genBlueDark pt-4 pt-md-0">Contact</h2>
                    <hr class="col-6">
                    <p class="fw-500 bold mb-0">Main Office</p>
                    <p class="fw-300 fs15">(803) 791-0995</p>
                    <p class="fw-500 bold mb-0">Fax Number</p>
                    <p class="fw-300 fs15">(803) 791-9087</p>
                    <p class="fw-500 bold mb-0">Email</p>
                    <p class="mb-0">nextgenins1@gmail.com</p>
                </b-col>
            </b-row>
            
            <b-row class="pt-md-4"> 
                <b-col class="text-center col-12 col-md-6 pt-4 pt-md-0">
                    <h2 class="fw700 genBlueDark">Habla español?</h2>
                    <hr class="col-6">
                    <p class="fw-500 bold mb-0">Número de oficina</p>
                    <p>(803) 794-8484</p>
                </b-col>
                
                <b-col class="text-center col-12 col-md-6 pt-4 pt-md-0">
                    <h2 class="fw700 genBlueDark">Social Media</h2>
                    <hr class="col-6">
                    <p>
                        <a href="https://www.facebook.com/NextGenIns1/" target="_blank"><b-icon icon="facebook" class="fs26"></b-icon></a>
                    </p>
                </b-col>
            </b-row>
        </b-col>
        
        <hr>
        
        <b-col class="px-0 col-md-6 mx-auto">
            <b-col class="text-center">
                <h2 class="fw700 genBlueDark">Location</h2>
                <hr class="col-6">
                <p class="fw-500 bold mb-0">Main Office</p>
                <p><a href="https://www.google.com/maps/place/1082+Sunset+Blvd,+West+Columbia,+SC+29169/@33.9982788,-81.0770699,18z/data=!3m1!4b1!4m5!3m4!1s0x88f8bca8325ef821:0xc94c855f0d463956!8m2!3d33.9982772!4d-81.0762734" target="_blank">1082 Sunset Blvd D, West Columbia, SC 29169</a></p>
            </b-col>
            <div>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3307.775861727675!2d-81.07846208505495!3d33.99829048405803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f8bd5dd5fab0e3%3A0x678336ce0664de1e!2sNext%20Gen%20Insurance!5e0!3m2!1sen!2sus!4v1651079326179!5m2!1sen!2sus" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" class="w-100"></iframe>
            </div>
        </b-col>
        </b-row>
    </div>
</template>


<script>

export default {
    data() {
        return {
            foods: [
                { value: null, text: "Choose..." },
                { value: "apple", text: "Apple" },
                { value: "orange", text: "Orange" },
            ],
            form: {
                name: null,
                food: null,
            },
        };
    },

    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? !$error : null;
        },
        resetForm() {
            this.form = {
                name: null,
                food: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            alert("Form submitted!");
        },
    },
};
</script>